
.bg-indigo-100,
.bg-blue-100,
.hover\:bg-blue-100:hover {
    background-color: var(--color100);
}

.bg-blue-200,
.hover\:bg-blue-200:hover {
    background-color: var(--color200);
}   

.bg-blue-300,
.hover\:bg-blue-300:hover {
    background-color: var(--color300);
}

.bg-blue-400,
.hover\:bg-blue-400:hover {
    background-color: var(--color400);
}

.bg-blue-500,
.hover\:bg-blue-500:hover {
    background-color: var(--color500);
}

.bg-blue-600,
.hover\:bg-blue-600:hover {
    background-color: var(--color600);
}

.bg-blue-700,
.hover\:bg-blue-700:hover {
    background-color: var(--color700);
}

.bg-blue-800,
.hover\:bg-blue-800:hover {
    background-color: var(--color800);
}

.bg-blue-900,
.hover\:bg-blue-900:hover {
    background-color: var(--color900);
}

.text-blue-100,
.hover\:text-blue-100:hover {
    color: var(--color100);
}

.text-blue-200,
.hover\:text-blue-200:hover {
    color: var(--color200);
}

.text-blue-300,
.hover\:text-blue-300:hover {
    color: var(--color300);
}

.text-blue-400,
.hover\:text-blue-400:hover {
    color: var(--color400);
}

.text-blue-500,
.hover\:text-blue-500:hover {
    color: var(--color500);
}

.text-blue-600,
.hover\:text-blue-600:hover {
    color: var(--color600);
}

.text-blue-700,
.hover\:text-blue-700:hover {
    color: var(--color700);
}

.text-blue-800,
.hover\:text-blue-800:hover {
    color: var(--color800);
}

.text-blue-900,
.hover\:text-blue-900:hover {
    color: var(--color900);
}

.border-blue-100 {
    border-color: var(--color100);
}

.border-blue-200 {
    border-color: var(--color200);
}

.border-blue-300 {
    border-color: var(--color300);
}

.border-blue-400 {
    border-color: var(--color400);
}

.border-blue-500 {
    border-color: var(--color500);
}

.border-blue-600 {
    border-color: var(--color600);
}

.border-blue-700 {
    border-color: var(--color700);
}

.border-blue-800 {
    border-color: var(--color800);
}

.border-blue-900 {
    border-color: var(--color900);
}

.st0 {
    fill: var(--color500) !important;
}
.st00 {
    fill: var(--color300) !important;
}
.st1 {
    fill: var(--color200) !important;
}
.st2 {
    fill: var(--color700) !important;
}
.st3 {
    fill: var(--color400) !important;
    stroke: var(--color400) !important;
}
.st33 {
    fill: var(--color400) !important;
}
.st4 {
    fill: var(--color600) !important;
    stroke: var(--color500) !important;
}
.st44 {
    fill: var(--color600) !important;
}
.st444 {
    fill:none;
    stroke: var(--color600) !important;
}
.st4444 {
    stroke:none!important;
    fill: var(--color500) !important;
}
.st5 {
    fill: var(--color300) !important;
}
.st6 {
    fill: var(--color400) !important;
}
.st7 {
    fill: var(--color700) !important;
}
.st8 {
    stroke: var(--color500) !important;
}
.st16 {
    stroke: var(--color600) !important;
}
.st17 {
    stroke: var(--color600) !important;
}
.st22 {
    fill: var(--color200) !important;
    stroke: var(--color600) !important;
}
.st222 {
    fill:none!important;
    stroke: var(--color700) !important;
    stroke-width: 1.7 !important;
}



.ps-submenu-content {
    width: auto !important;
}

.ps-submenu-content-button:hover {
    background-color: var(--color700)  !important;
    color: white !important;

}

.ps-menu-button:hover {
    background-color: var(--color500) !important;
    color: white !important;

}
.ps-submenu-content {
    background-color: var(--color400) !important;
    color: white !important;
}
.theblue {
    color: var(--color500) !important;
}
.fade-in {
    opacity: 0 !important;
    transition: opacity 0.8s ease-in-out !important;
}

.fade-in.visible {
    opacity: 1 !important;
}

@media (min-width: 1281px) {
    .chart {
        width: 49.5% !important;
    }
    .stick {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .chart {
        width: 49% !important;
    }
    .stick {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}